<template>
  <div class="webData">
    <el-card class="box-card">
      <template v-slot:header>
        <my-breadcrumb>网站编辑</my-breadcrumb>
      </template>
      <el-collapse v-model="activeNames">
        <el-collapse-item title="添加网站" name="1">
          <el-form ref="myForm" :model="myForm" :rules="rules">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="12" :xl="8">
                <el-form-item label="网站名称" prop="webName">
                  <el-input v-model="myForm.webName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="12" :xl="8">
                <el-form-item label="网站链接" prop="webLinkUrl">
                  <el-input v-model="myForm.webLinkUrl"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="12" :xl="8">
                <el-form-item label="网站描述" prop="webDescribe">
                  <el-input v-model="myForm.webDescribe"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="12" :xl="24">
                <el-form-item label="网站logo" prop="webLogo">
                  <el-input type="textarea" v-model="myForm.webLogo"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="12" :xl="24">
                <el-form-item label="分类分级" prop="selectedOptions">
                  <el-cascader
                    expand-trigger="hover"
                    :options="navOptions"
                    v-model="myForm.selectedOptions"
                    @change="handleChange"
                  >
                  </el-cascader>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item>
              <el-button type="primary" @click="onSubmit('myForm')"
                >立即创建</el-button
              >
              <el-button @click="resetForm('myForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-card class="box-card">
      <template v-slot:header>
        <p>根据筛选条件, 共查询到 {{ total }} 条结果:</p>
      </template>
      <el-table :data="webData" v-loading="isLoading">
        <el-table-column label="网站名称">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.isClick"
              v-model="scope.row.webName"
            ></el-input>
            <span v-else>{{ scope.row.webName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="网站链接">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.isClick"
              v-model="scope.row.webLinkUrl"
            ></el-input>
            <a v-else style="color:red;fontSize:20px">{{
              scope.row.webLinkUrl
            }}</a>
          </template>
        </el-table-column>
        <el-table-column label="网站描述">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.isClick"
              v-model="scope.row.webDescribe"
            ></el-input>
            <span v-else>{{ scope.row.webDescribe }}</span>
          </template>
        </el-table-column>
        <el-table-column label="网站logo">
          <template slot-scope="scope">
            <el-input
              v-if="scope.row.isClick"
              v-model="scope.row.webLogo"
            ></el-input>
            <span v-else>{{ scope.row.webLogo }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              v-if="!scope.row.isClick"
              @click="handleEdit(scope.row)"
              size="small"
              >编辑</el-button
            >
            <el-button
              size="small"
              type="danger"
              v-if="!scope.row.isClick"
              @click="handleDel(scope.row)"
              >删除</el-button
            >
            <el-button
              v-if="scope.row.isClick"
              @click="handleCancel(scope.row)"
              size="small"
              type="warning"
              >取消</el-button
            >
            <el-button
              v-if="scope.row.isClick"
              @click="handleSave(scope.row)"
              size="small"
              type="success"
              >保存</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import MyBreadcrumb from '@/components/my-breadcrumb.vue'
import ListData from '@/data/tool.json'
export default {
  name: 'WebsiteEdit',
  components: {
    MyBreadcrumb
  },
  data() {
    return {
      myForm: {
        selectedOptions: [],
        webName: '',
        webLinkUrl: '',
        webDescribe: '',
        webLogo:
          'https://is1-ssl.mzstatic.com/image/thumb/Purple128/v4/05/1d/cd/051dcdd9-d6cc-7c05-6927-ae337677974d/AppIcon-1x_U007emarketing-85-220-0-8.png/230x0w.jpg'
      },
      rules: {
        webName: [
          { required: true, message: '请输入网站名称', trigger: 'blur' }
        ],
        webLinkUrl: [
          { required: true, message: '请输入网站链接', trigger: 'blur' }
        ],
        webDescribe: [
          { required: true, message: '请输入网站描述', trigger: 'blur' }
        ],
        webLogo: [
          { required: true, message: '请输入网站logo', trigger: 'blur' }
        ],
        selectedOptions: [
          { required: true, message: '请选择网站分类', trigger: 'change' }
        ]
      },
      navOptions: [
        {
          value: 'movies',
          label: '影视导航',
          children: [
            {
              value: '1-1',
              label: '主流平台'
            },
            {
              value: '2-1',
              label: '我爱追剧'
            },
            {
              value: '3-1',
              label: '视频教程'
            }
          ]
        },
        {
          value: 'lifeFan',
          label: '生活百科',
          children: [
            {
              value: '1-1',
              label: '发现知道'
            },
            {
              value: '2-1',
              label: '新闻资讯'
            },
            {
              value: '3-1',
              label: '电商平台'
            }
          ]
        },
        {
          value: 'softwareResources',
          label: '软件资源',
          children: [
            {
              value: '1-1',
              label: '发现软件'
            },
            {
              value: '2-1',
              label: '资源搜索'
            },
            {
              value: '3-1',
              label: '在线文档'
            }
          ]
        },
        {
          value: 'designNav',
          label: '设计导航',
          children: [
            {
              value: '1-1',
              label: '设计工具'
            },
            {
              value: '2-1',
              label: '素材资源'
            }
          ]
        },
        {
          value: 'programList',
          label: '俺是程序员',
          children: [
            {
              value: '1-1',
              label: '前端开发'
            },
            {
              value: '2-1',
              label: '后端开发'
            },
            {
              value: '3-1',
              label: '博客文档'
            }
          ]
        },
        {
          value: 'junxiaoList',
          label: '军校导航',
          children: [
            {
              value: '1-1',
              label: 'pc端'
            },
            {
              value: '2-1',
              label: '移动端'
            },
            {
              value: '3-1',
              label: '军校周边'
            },
            {
              value: '4-1',
              label: '我的工作'
            }
          ]
        }
      ],
      currentPage: 1,
      pageSize: 10,
      webData: [],
      total: 0,
      isLoading: false,
      activeNames: ['1']
    }
  },
  created() {},
  mounted() {
    this.getWebSite()
    this.total = ListData.list.length
  },
  methods: {
    getWebSite() {
      const url = `http://localhost:3000/list?_page=${this.currentPage}&_limit=${this.pageSize}`
      this.$http
        .get(url)
        .then(res => {
          this.webData = res.data
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    handleChange(value) {
      console.log(value)
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const url = 'http://localhost:3000/list'
          const params = { ...this.myForm }
          this.$http
            .post(url, params)
            .then(() => {
              this.$message.success('创建成功')
              this.getWebSite()
              this.$refs[formName].resetFields()
            })
            .catch(err => {
              this.$refs[formName].resetFields()
              this.$message.error(err)
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getWebSite()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getWebSite()
    },
    handleEdit(row) {
      row.isClick = true
      console.log('XXXXXX--loncon_row', row)
    },
    handleCancel(row) {
      row.isClick = false
    },
    handleSave(row) {
      row.isClick = false
      const url = `http://localhost:3000/list/${row.id}`
      const params = {
        ...row
      }
      this.$http
        .put(url, params)
        .then(res => {
          this.$message.success('更新成功')
          this.getWebSite()
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    handleDel(row) {
      const url = `http://localhost:3000/list/${row.id}`
      this.$http
        .delete(url)
        .then(res => {
          console.log('XXXXXX--loncon_res', res)
          this.getWebSite()
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.webData {
  .box-card {
    margin-bottom: 20px;
  }
  /deep/ .el-collapse-item__header {
    color: #002033;
    font-weight: bold;
    justify-content: center;
  }
}
</style>
